import Vue, { VueConstructor } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAppInsights from "vue-application-insights";
import VueMixpanel from "vue-mixpanel";
import { BootstrapVue, BootstrapVueIcons, CollapsePlugin } from "bootstrap-vue";
import VueMatomo from "vue-matomo";
import { Auth0Plugin, useAuth0, VueAuth } from "@/lib/auth/VueAuth";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import rg4js from "raygun4js";
import HighchartsMore from "highcharts/highcharts-more";
import config from "@/lib/config";
import { getResource } from "@/lib/resources";
import VueI18n from "vue-i18n";
import Hotjar from "vue-hotjar";

Highcharts.AST.allowedAttributes.push("data-trait");

Vue.use(HighchartsVue);
HighchartsMore(Highcharts);

Vue.config.productionTip = false;

import "@/assets/style/main.scss";
import "@/assets/style/themes.scss";
import axios from "axios";
import { AssessmentPlatformConfig } from "./store/types";
import { Resource } from "./store/admin/adminTypes";
import { Roles } from "./lib/auth/User";

type AuthPromise = { authObj: VueAuth; token?: string };

export const ConfigPlugin = {
  install(Vue: VueConstructor, options: { config: AssessmentPlatformConfig }): void {
    Vue.prototype.$config = options.config;
  },
};

export const ResourcesPlugin = {
  install(Vue: VueConstructor, options: { resource: Resource }): void {
    Vue.prototype.$resource = options.resource;
  },
};

const url = new URLSearchParams(window.location.search);

Promise.all([getResource("admin", url.get("lang") ?? "en")])
  .then(([resource]) => {
    useAuth0({
      domain: config.AUTH0_DOMAIN,
      clientId: config.AUTH0_CLIENTID,
      audience: config.AUTH0_AUDIENCE,
      onRedirectCallback: async (appState: any) => {
        await router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
      },
    })
      .then<AuthPromise>(async (authObj) => {
        if (await authObj.isAuthenticated) {
          return authObj.getTokenSilently({})?.then<{ authObj: VueAuth; token: string }>((token) => {
            return { authObj, token };
          }) as Promise<AuthPromise>;
        }

        return { authObj };
      })
      .then(async ({ authObj, token }) => {
        Vue.use(BootstrapVue);
        Vue.use(BootstrapVueIcons);
        Vue.use(CollapsePlugin);
        Vue.use(Auth0Plugin, { authObj });
        Vue.use(ConfigPlugin, { config });
        Vue.use(ResourcesPlugin, { resource });
        Vue.use(VueI18n);

        // Do not load hotjar for global admin
        const user = (await authObj?.getUser()) ?? null;
        if (!user?.roles?.includes(Roles.GLOBAL_ADMIN)) {
          Vue.use(Hotjar, {
            id: "3783500", // Hotjar Site ID TODO: Move to environment variable
            isProduction: !!config.PRODUCTION,
          });
        }

        if (config.MIXPANEL_TOKEN) {
          Vue.use(VueMixpanel, {
            token: config.MIXPANEL_TOKEN,
            config: {
              debug: !config.PRODUCTION,
            },
          });
        }

        const i18n = new VueI18n({
          locale: url.get("lang") ?? "en",
          messages: { [url.get("lang") ?? "en"]: resource.dictionary },
        });

        // Set the language based on the query parameter
        router.beforeEach(async (to, from, next) => {
          const lang = (to.query.lang ?? "en") as string;

          // Load the language if it hasn't been loaded yet
          if (!i18n.availableLocales.includes(lang)) {
            const resource = await getResource("admin", lang);
            i18n.setLocaleMessage(lang, resource.dictionary);
          }

          // Set i18n language
          i18n.locale = lang;

          next();
        });

        // TODO: We might be able to delete this
        if (token) {
          axios.interceptors.request.use((config) => {
            config.headers!.Authorization = `Bearer ${token}`;
            return config;
          });
        }

        // Init RayGun
        if (config.RAYGUN_KEY) {
          rg4js("apiKey", config.RAYGUN_KEY);
          rg4js("enableCrashReporting", true);
          rg4js("logContentsOfXhrCalls", config.RAYGUN_VERBOSE);
          rg4js("options", {
            excludedHostnames: ["localhost"],
            ignoreAjaxAbort: !config.RAYGUN_VERBOSE,
            ignoreAjaxError: !config.RAYGUN_VERBOSE,
            debugMode: !config.PRODUCTION,
          });
          rg4js("setVersion", config.APP_VERSION);
        }

        if (config.APPINSIGHTS_KEY) {
          Vue.use(VueAppInsights, {
            id: config.APPINSIGHTS_KEY,
            router,
          });
        }

        if (config.MATOMO_HOST && config.MATOMO_SITEID) {
          Vue.use(VueMatomo, {
            host: config.MATOMO_HOST,
            siteId: config.MATOMO_SITEID,
            enableHeartBeatTimer: true,
            router,
          });
        }

        Vue.config.errorHandler = function (err, vm, info) {
          rg4js("send", {
            error: err,
            customData: [{ info: info }],
          });
          if (!config.PRODUCTION) {
            console.error(err, info);
          }
        };

        new Vue({
          router,
          store,
          i18n,
          render: (h) => h(App),
        }).$mount("#app");
      })
      .catch((err) => console.error(err));
  })
  .catch((err) => console.error(err));


import GenerateLinkComponent from "@/components/platform/GenerateLinkComponent.vue";

import { TypedVue } from "@/store/types";
import { Component, Watch, Ref, Prop } from "vue-property-decorator";
import { Modal } from "bootstrap";
import { V2Project } from "@/store/admin/adminTypes";

@Component({ components: { GenerateLinkComponent } })
export default class AddParticipantModal extends TypedVue {
  @Ref("add-participant-modal")
  readonly modal!: Modal;

  @Prop() show!: boolean;
  @Prop({ default: null }) project!: V2Project | null;

  @Watch("show")
  onShowChange(show: boolean) {
    if (show) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  close(): void {
    this.$emit("close");
  }
}

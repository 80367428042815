
import { TypedVue } from "@/store/types";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Project, Assessment, Benchmark, ProjectReportType } from "@/store/internal/internalTypes";
import { TRAITS } from "@/lib/consts";
import ComparisonRadarChartComponent, { ComparisonAssessmentItem, ComparisonBenchmarkItem, ComparisonChartPreset } from "@/components/admin/ComparisonComponents/ComparisonRadarChartComponent.vue";
import { AssessmentState, CreateReport, SchemasCandidateAssessment, Scoring } from "@/lib/serviceTypes";
import { Benchmark as AdminSchemaBenchmark } from "@/store/admin/adminTypes";
import { ColorMap } from "@/lib/charts/HighChartThemes";
import Vue from "vue";
import { Action } from "vuex-class";
import { Actions } from "@/store/internal/interalActions";

import MarkdownEditor from "../input/MarkdownEditor.vue";

@Component({
  components: {
    ComparisonRadarChartComponent,
    MarkdownEditor,
  },
})
export default class NewProjectReport extends TypedVue {
  @Prop()
  public project!: Project;

  @Prop()
  public assessments!: Assessment[];

  @Prop()
  public benchmarks!: Benchmark[];

  @Prop({ default: null })
  public initialReport!: {
    identifier: string;
    name: string;
    description: string;
    version: string;
    type: ProjectReportType;
    scoringVersion: string;
    assessmentIds: string[];
    data: {
      traitPriorities: { [trait: string]: number };
      reportOverviewCustomText: string;
      teamAlignmentMustHaveCustomText: string;
      teamAlignmentAllCustomText: string;
      teamMatrixMustHaveCustomText: string;
      teamMatrixTopPriorityCustomText: string;
      comparisonPresets: ComparisonChartPreset[];
      demoMode?: boolean;
    };
  } | null;

  @Action(Actions.SAVE_PROJECT_REPORT, { namespace: "internal" })
  public saveProjectReport!: (reportArgs: { projectId: string; projectReport: CreateReport }) => Promise<string>;

  @Action(Actions.UPDATE_REPORT, { namespace: "internal" })
  public updateProjectReport!: (data: { reportId: string; name: string; description: string | undefined; assessmentIds: string[]; data: Record<string, unknown> }) => Promise<void>;

  private mapAssessmentToSchemaAssessment(assessment: Assessment, score: Scoring): SchemasCandidateAssessment {
    return {
      assessmentId: assessment.identifier,
      candidateName: assessment.candidate.name,
      isComplete: assessment.state === AssessmentState.ScoringComplete,
      project: assessment.projectId,
      organization: assessment.company,
      competencyScores: score.competencyScores,
      competencies: score.competencies,
      traitScores: score.traitScores,
      readinessScore: score.readiness,
    }; // TODO: We need to migrate the comparison radar chart to use the new schema and we can remove this
  }

  private mapBenchmarkToSchemaBenchmark(benchmark: Benchmark): AdminSchemaBenchmark {
    return {
      alwaysShow: benchmark.default,
      benchmarkId: benchmark.identifier,
      colour: benchmark.colour,
      name: benchmark.name,
      competencyScores: benchmark.competencyScores,
      isAdmin: benchmark.isAdmin,
      organization: benchmark.orgId,
      traitScores: benchmark.traitScores,
      traitRanges: benchmark.traitRanges,
      dateCreated: "",
      lastUpdated: "",
    }; // TODO: We need to migrate the comparison radar chart to use the new schema and we can remove this
  }

  get assesmentDisplayList() {
    return this.assessments
      .filter((assessment) => assessment.report)
      .filter((assessment) => assessment.scores?.find((x) => x.scoringVersion === this.scoringVerison))
      .map((assessment) => ({
        id: assessment.identifier,
        name: `${assessment.identifier.substring(0, 4)} - ${assessment.candidate.name}`,
      }));
  }

  public reportName = "";
  public reportDescription = "";
  public reportVersion = "1.0";
  public scoringVerison = "v2";
  public reportType: ProjectReportType = "team-effectiveness";
  public selectedAssessmentIds: string[] = [];
  public traitPriorities: { [trait: string]: number } = {};
  public traits = TRAITS;
  public allAssessmentsSelected = false;
  public reportOverviewCustomText = "";
  public teamAlignmentMustHaveCustomText = "";
  public teamAlignmentAllCustomText = "";
  public teamMatrixMustHaveCustomText = "";
  public teamMatrixTopPriorityCustomText = "";
  public generatedReportId: null | string = null;
  public demoMode = false;

  public compariableBenchmarks = Vue.observable<ComparisonBenchmarkItem[]>([]);
  public compariableAssessments = Vue.observable<ComparisonAssessmentItem[]>([]);
  public comparisonPresets = Vue.observable<ComparisonChartPreset[]>([]);

  public editMode = false;
  public traitRankChoices = [
    { value: 2, text: "Must Have" },
    { value: 1, text: "Nice to Have" },
    { value: 0, text: "Neutral" },
  ];

  public async saveReport() {
    if (this.editMode) {
      await this.updateProjectReport({
        reportId: this.initialReport?.identifier || "",
        name: this.reportName,
        description: this.reportDescription,
        assessmentIds: this.selectedAssessmentIds,
        data: {
          traitPriorities: this.traitPriorities,
          reportOverviewCustomText: this.reportOverviewCustomText,
          teamAlignmentMustHaveCustomText: this.teamAlignmentMustHaveCustomText,
          teamAlignmentAllCustomText: this.teamAlignmentAllCustomText,
          teamMatrixMustHaveCustomText: this.teamMatrixMustHaveCustomText,
          teamMatrixTopPriorityCustomText: this.teamMatrixTopPriorityCustomText,
          comparisonPresets: this.comparisonPresets,
          demoMode: this.demoMode,
        },
      });
      this.generatedReportId = this.initialReport?.identifier || "";
    } else {
      this.generatedReportId = await this.saveProjectReport({
        projectId: this.project.identifier,
        projectReport: {
          name: this.reportName,
          description: this.reportDescription,
          reportVersion: this.reportVersion,
          reportType: this.reportType,
          scoringVersion: this.scoringVerison,
          assessmentIds: this.selectedAssessmentIds,
          data: {
            traitPriorities: this.traitPriorities,
            reportOverviewCustomText: this.reportOverviewCustomText,
            teamAlignmentMustHaveCustomText: this.teamAlignmentMustHaveCustomText,
            teamAlignmentAllCustomText: this.teamAlignmentAllCustomText,
            teamMatrixMustHaveCustomText: this.teamMatrixMustHaveCustomText,
            teamMatrixTopPriorityCustomText: this.teamMatrixTopPriorityCustomText,
            comparisonPresets: this.comparisonPresets,
            demoMode: this.demoMode,
          },
        },
      });
    }
  }

  public removePreset(preset: ComparisonChartPreset) {
    const index = this.comparisonPresets.findIndex((x) => x.id === preset.id);
    if (index !== -1) {
      this.comparisonPresets.splice(index, 1);
    }
  }

  public savePreset(preset: ComparisonChartPreset) {
    this.comparisonPresets.push(preset);
  }

  public updatePreset(preset: ComparisonChartPreset) {
    const index = this.comparisonPresets.findIndex((x) => x.id === preset.id);
    if (index !== -1) {
      this.comparisonPresets.splice(index, 1, preset);
    }
  }

  public toggleAll() {
    this.selectedAssessmentIds = this.selectedAssessmentIds.length !== this.assesmentDisplayList.length ? this.assesmentDisplayList.slice().map((x) => x.id) : [];
  }

  public setComparableAssessments() {
    this.compariableAssessments = this.assessments
      .filter((assessment) => this.selectedAssessmentIds.includes(assessment.identifier))
      .filter((assessment) => assessment.scores?.find((x) => x.scoringVersion === "v2"))
      .map((assessment) => ({ assessment, scoring: assessment.scores?.find((x) => x.scoringVersion === "v2") as Scoring }))
      .map(({ assessment, scoring }) => ({
        id: assessment.identifier,
        name: `${assessment.identifier.substring(0, 4)} - ${assessment.candidate.name}`,
        hidden: false,
        isLocked: false,
        selected: false,
        color: ColorMap[this.project.theme][scoring.readiness],
        initialColor: ColorMap[this.project.theme][scoring.readiness],
        data: this.mapAssessmentToSchemaAssessment(assessment, scoring),
      }));
  }

  @Watch("selectedAssessmentIds")
  public onSelectedAssessmentIdsChange(newValue: string[], oldValue: string[]) {
    this.allAssessmentsSelected = newValue.length === this.assessments.length;
    this.setComparableAssessments();

    if (newValue.length < oldValue.length) {
      const removedAssessmentIds = oldValue.filter((x) => !newValue.includes(x));
      for (const removedAssessmentId of removedAssessmentIds) {
        for (const preset of this.comparisonPresets) {
          preset.items = preset.items.filter((x) => x.id !== removedAssessmentId);
        }
      }
    }
  }

  mounted() {
    if (this.initialReport) {
      if (this.initialReport.type !== this.reportType) {
        throw new Error("Only team-effectiveness reports are supported");
      }
      this.editMode = true;
      this.reportName = this.initialReport.name;
      this.reportDescription = this.initialReport.description;
      this.reportVersion = this.initialReport.version;
      this.selectedAssessmentIds = this.initialReport.assessmentIds;
      this.traitPriorities = this.initialReport.data.traitPriorities;
      this.reportOverviewCustomText = this.initialReport.data.reportOverviewCustomText;
      this.teamAlignmentMustHaveCustomText = this.initialReport.data.teamAlignmentMustHaveCustomText;
      this.teamAlignmentAllCustomText = this.initialReport.data.teamAlignmentAllCustomText;
      this.teamMatrixMustHaveCustomText = this.initialReport.data.teamMatrixMustHaveCustomText;
      this.teamMatrixTopPriorityCustomText = this.initialReport.data.teamMatrixTopPriorityCustomText;
      this.comparisonPresets = this.initialReport.data.comparisonPresets;
      this.demoMode = this.initialReport.data.demoMode || false;
    } else {
      this.reportName = `${this.project.name} Team Effectiveness Report`;
      this.traitPriorities = this.traits.reduce((acc, trait) => {
        acc[trait] = 0;
        return acc;
      }, {} as { [trait: string]: number });
    }

    this.compariableBenchmarks = this.benchmarks.map((benchmark) => ({
      id: benchmark.identifier,
      name: benchmark.name,
      hidden: false,
      isLocked: false,
      selected: benchmark.default || false,
      color: benchmark.colour,
      initialColor: benchmark.colour,
      data: this.mapBenchmarkToSchemaBenchmark(benchmark),
    }));
    this.setComparableAssessments();
  }
}

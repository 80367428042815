import { Axios, AxiosError } from "axios";
import axios from "axios"; // tslint:disable-line
import { Assessment, Benchmark, OrganizationChart, Project, Status, Organization, ElraReportData, CompetencyScores, Resource, TraitScores } from "@/store/admin/adminTypes";
import { getInstance } from "@/lib/auth/VueAuth";
import router from "@/router";
import config from "@/lib/config";
import { CanadidateReportInfo } from "@/store/types";
class AdminService {
  private api: Axios;

  constructor(baseAPIUrl = "/api/admin") {
    this.api = axios.create({ baseURL: baseAPIUrl });

    // Add auth token to all requests
    this.api.interceptors.request.use(async (config) => {
      const auth = getInstance();
      if (auth) {
        const token = await auth.getTokenSilently({});
        if (config && config.headers) {
          config.headers.Authorization = token ? `Bearer ${token}` : "";
        }
      }

      return config;
    });

    // Redirect on 401 unauthorized reponses
    // TODO: This should not be in the service layer, move out
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          router.push("/accessdenied").catch((err) => console.error(err));
        }
        return Promise.reject(error);
      },
    );
  }

  public async uploadOrganizationChart(chartFile: File, projectId: string, orgChartTitle: string): Promise<{ success: boolean; detail: string }> {
    const formData = new FormData();
    formData.append("project_id", projectId);
    formData.append("org_chart_title", orgChartTitle);
    formData.append("file", chartFile);

    const req = await this.api.post<{ detail: string }>(`/chart`, formData, {
      headers: {
        "Content-Type": "application/json",
      },
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });
    if (req.status === 201) {
      return { success: true, detail: "" };
    } else {
      return { success: false, detail: req.data.detail };
    }
  }

  async bulkCreateAssessments(bulkFile: File): Promise<{ success: boolean; detail: string | Blob }> {
    const formData = new FormData();
    formData.append("file", bulkFile);
    const req = await this.api.post<Blob | { detail: string }>(`/assessments/bulk`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
      responseType: "blob",
    });

    if (req.status === 200) {
      return { success: true, detail: req.data as Blob };
    } else {
      return { success: false, detail: (req.data as { detail: string }).detail };
    }
  }

  public async generateNewAssessmentToken(
    candidateName: string,
    candidateEmail: string,
    hiringCompany: string,
    hiringProject: string,
    projectType: string,
    sendEmail: boolean,
    language: string,
    auto_generate_report = false,
  ): Promise<string> {
    const req = await this.api.post<{ token: string }>(`/assessments`, {
      candidateName,
      candidateEmail,
      organization: hiringCompany,
      project: hiringProject,
      projectType: projectType,
      language,
      sendEmail,
      auto_generate_report,
    });

    return req.data.token;
  }

  public async resetAssessmentToken(assessmentId: string): Promise<string> {
    const req = await this.api.post<{ token: string }>(`/assessment-regen`, {
      assessmentId: assessmentId,
    });

    return req.data.token;
  }

  public async resetAssessmentAttempts(assessmentId: string): Promise<void> {
    await this.api.post(`/assessment-reset`, {
      assessmentId: assessmentId,
    });
  }

  public async getOrganizationStatus(project?: string | null): Promise<Status> {
    const req = await this.api.get<Status>("/organization/status", {
      params: { project },
    });

    return req.data;
  }

  public async getProjectAssessments(projectId: string): Promise<{ count: number; assessments: Assessment[] }> {
    const req = await this.api.get<{
      count: number;
      assessments: Assessment[];
    }>("/organization/assessments", { params: { project: projectId } });

    return req.data;
  }

  public async updateAssessment(assessmentId: string, pinned: boolean): Promise<void> {
    await this.api.patch(`/organization/assessments/${assessmentId}`, {
      pinned,
    });
  }

  public async getOrganizationProjects(): Promise<Project[]> {
    const req = await this.api.get<{ projects: Project[] }>("/organization/projects");

    return req.data.projects;
  }

  public async getBenchmarks(): Promise<Benchmark[]> {
    const req = await this.api.get<{ benchmarks: Benchmark[] }>("/organization/benchmarks");

    return req.data.benchmarks;
  }

  public async getProjectBenchmarks(projectId: string): Promise<Benchmark[]> {
    const req = await this.api.get<{ benchmarks: Benchmark[] }>(`/organization/project/${projectId}/benchmarks`);

    if (req.status === 200) {
      return req.data.benchmarks || [];
    } else {
      return [];
    }
  }

  public async getCandidateBenchmarks(candidateId: string): Promise<Benchmark[]> {
    const req = await this.api.get<{ benchmarks: Benchmark[] }>(`/candidate/${candidateId}/benchmarks`);

    return req.data.benchmarks;
  }

  public async getAssessmentsCsv(project: string): Promise<Blob> {
    const req = await this.api.get<Blob>("/assessment-dump", {
      params: {
        project,
      },
      responseType: "blob",
    });

    return req.data;
  }

  public async getOrganizationChart(project: string): Promise<OrganizationChart> {
    const req = await this.api.get<OrganizationChart>("/organization/chart", {
      params: { project },
    });

    return req.data;
  }

  public async getCandidateReportData(assessmentId: string): Promise<CanadidateReportInfo | null> {
    /* TODO: Type info for report */
    const req = await this.api.get<CanadidateReportInfo>(`/organization/report/${assessmentId}/render-data`, {
      validateStatus: (status) => status === 200 || status === 404,
    });

    return req.status === 200 ? req.data : null;
  }

  public async getCandidateReport(assessmentId: string, showNames = false): Promise<{ exists: boolean; report: string }> {
    const req = await this.api.get<string>(`/organization/report/${assessmentId}${showNames ? "?show_name=true" : ""}`, {
      validateStatus: (status) => status === 200 || status === 404,
    });

    if (req.status === 200) {
      return { exists: true, report: req.data };
    }
    return { exists: false, report: "" };
  }

  public async getReportAsCandidate(assessmentId: string): Promise<{ exists: boolean; report: string }> {
    const req = await this.api.get<string>(`/candidate/report/${assessmentId}`, {
      validateStatus: (status) => status === 200 || status === 404,
    });

    if (req.status === 200) {
      return { exists: true, report: req.data };
    }
    return { exists: false, report: "" };
  }

  public async getOrganizations(): Promise<Organization[]> {
    const req = await this.api.get<{ organizations: Organization[] }>(`/organizations`);

    return req.data.organizations;
  }

  public async getProjects(organization: string | null, assessment: string | null, includeDeleted = false): Promise<Project[]> {
    const params: Record<string, any> = { include_deleted: includeDeleted };
    if (organization !== null) {
      params.organization = organization;
    }
    if (assessment !== null) {
      params.assessment = assessment;
    }

    const req = await this.api.get<{ projects: Project[] }>("/projects", { params });
    return req.data.projects;
  }

  public async getProjectsForBenchmark(benchmarkId: string): Promise<Project[]> {
    const req = await this.api.get<{ projects: Project[] }>(`/internal/benchmarks/${benchmarkId}/projects`);

    return req.data.projects;
  }

  public async getAllProjectsInOrganization(organization: string): Promise<Project[]> {
    return this.getProjects(organization, null, true);
  }

  public async getAssessments(project: string): Promise<{ count: number; assessments: Assessment[] }> {
    const req = await this.api.get<{
      count: number;
      assessments: Assessment[];
    }>("/assessments", {
      params: { project },
    });

    return req.data;
  }

  public async getCandidateAssessment(assessmentId: string): Promise<Assessment> {
    const req = await this.api.get<Assessment>(`/candidate/${assessmentId}/assessment`);

    return req.data;
  }

  public async getReportTemplateData(assessmentId: string, scoringVersion: string): Promise<ElraReportData> {
    const req = await this.api.get<ElraReportData>(`/report/${assessmentId}/template-data`, { params: { scoring_version: scoringVersion } });

    return req.data;
  }

  async publishReport(assessmentId: string, reportData: ElraReportData, sendEmail = false, beta = false): Promise<{ success: boolean; detail: string }> {
    const req = await this.api.post(`/report/${assessmentId}`, reportData, {
      params: {
        send_email: sendEmail,
        beta,
      },
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });

    if (req.status === 201) {
      return { success: true, detail: "" };
    } else {
      return { success: false, detail: req.data.detail };
    }
  }

  async sendReportEmailToCandidate(assessmentId: string): Promise<{ success: boolean; detail: string }> {
    const req = await this.api.post(`/report/${assessmentId}/send-email`, null, {
      validateStatus: function (status) {
        return status < 500; // Resolve only if the status code is less than 500
      },
    });

    if (req.status === 201) {
      return { success: true, detail: "" };
    } else {
      return { success: false, detail: req.data.detail };
    }
  }

  async getAllProjects() {
    return this.getProjects(null, null, true);
  }

  async getCandidateProject(assessmentId: string) {
    const req = await this.api.get<Project>(`/candidate/${assessmentId}/project`);
    return req.data;
  }

  async deleteAssessment(assessmentId: string) {
    return this.api.delete(`/assessment-soft-delete/${assessmentId}`);
  }

  async recalculateAssessmentScores(assessmentId: string) {
    return this.api.post(`/assessments/${assessmentId}/recalculate`);
  }

  deleteProject(projectId: string) {
    return this.api.delete(`/projects/${projectId}`);
  }

  public async getAllAssessments(page: number, pageSize: number, sort: string, search: string): Promise<{ count: number; assessments: Assessment[] }> {
    const req = await this.api.get<{ count: number; assessments: Assessment[] }>("/all-assessments", {
      params: {
        page,
        page_size: pageSize,
        sort,
        search,
      },
    });
    return req.data;
  }

  async updateAssessmentDetails(assessmentId: string, candidateName: string, candidateEmail: string): Promise<{ success: boolean; detail: string }> {
    const req = await this.api.put("/assessment-edit", {
      assessmentId: assessmentId,
      candidateName: candidateName,
      candidateEmail: candidateEmail,
    });
    if (req.status < 300) {
      return { success: true, detail: "" };
    }
    return { success: false, detail: req.data.detail };
  }

  async updateProject(project: Project): Promise<Project> {
    const req = await this.api.patch<Project>(`/organization/project/${project.projectId}`, project);

    return req.data;
  }

  async addAssessmentToProject(assessmentId: string, projectId: string) {
    return this.api.post(`/internal/projects/${projectId}/assessments/${assessmentId}`);
  }

  async removeAssessmentFromProject(assessmentId: string, projectId: string) {
    return this.api.delete(`/internal/projects/${projectId}/assessments/${assessmentId}`);
  }

  async updateProjectDetails(projectId: string, name: string, description: string, logo: string) {
    return this.api.put(`/internal/projects/${projectId}`, { name, description, logo });
  }

  public async getAllBenchmarks() {
    const req = await this.api.get<{ benchmarks: Benchmark[] }>("/internal/benchmarks");
    return req.data.benchmarks;
  }

  async deleteBenchmark(benchmarkId: string) {
    return this.api.delete(`/internal/benchmarks/${benchmarkId}`);
  }

  async updateBenchmark(benchmark: Benchmark) {
    return this.api.patch(`/internal/benchmarks/${benchmark.benchmarkId}`, {
      organization: benchmark.organization,
      name: benchmark.name,
      description: benchmark.description,
      colour: benchmark.colour,
      competencyScores: benchmark.competencyScores,
      traitScores: benchmark.traitScores,
      assessments: benchmark.assessments,
      isAdmin: benchmark.isAdmin,
    });
  }

  async createBenchmark(benchmark: Benchmark) {
    return this.api.post("/internal/benchmarks", {
      organization: benchmark.organization,
      name: benchmark.name,
      description: benchmark.description,
      colour: benchmark.colour,
      competencyScores: benchmark.competencyScores,
      traitScores: benchmark.traitScores,
      assessments: benchmark.assessments,
      isAdmin: benchmark.isAdmin,
    });
  }

  async addBenchmarkToProject(benchmarkId: string, projectId: string) {
    return this.api.post(`/internal/projects/${projectId}/benchmarks/${benchmarkId}`);
  }

  async removeBenchmarkFromProject(benchmarkId: string, projectId: string) {
    return this.api.delete(`/internal/projects/${projectId}/benchmarks/${benchmarkId}`);
  }

  async getShortAssessments(onlyComplete = false): Promise<[string, string, string, string][]> {
    const res = await this.api.get<{ assessments: [string, string, string, string][] }>(`/assessments/short`, { params: { only_complete: onlyComplete } });

    return res.data.assessments;
  }

  async generateBenchmark(ids: string[], scoringVersion: string) {
    try {
      const res = await this.api.post<{ competencyScores: CompetencyScores; traitScores: TraitScores; detail: string }>("/internal/benchmarks/generate", { ids, scoringVersion });
      return {
        success: true,
        detail: "",
        competencyScores: res.data.competencyScores,
        traitScores: res.data.traitScores,
      };
    } catch (err) {
      return {
        success: false,
        detail: ((err as AxiosError)?.response?.data as any).detail,
        competencyScores: {},
        traitScores: {},
      };
    }
  }

  public async getScoringVersions() {
    const req = await this.api.get<{ versions: string[] }>("/internal/reports/scoring/versions");
    return req.data.versions;
  }

  public async getReportScoringVersions(assessmentId: string) {
    const req = await this.api.get<{ versions: string[] }>(`/internal/reports/${assessmentId}/scoring/versions`);
    return req.data.versions;
  }

  async getModuleResource(module: string, lang: string, version?: string): Promise<Resource> {
    const res = await this.api.get<Resource>(`/resources/${module}`, { params: { lang, version }, baseURL: "/api" });

    return res.data;
  }

  async updateCurrentUser(data: { acceptedTos?: boolean }): Promise<void> {
    await this.api.patch<void>("/users/me", data);
  }

  async uploadLogo(file: File): Promise<string> {
    const formData = new FormData();
    formData.append("file", file);
    const res = await this.api.post<{ url: string }>("/assets", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return res.data.url;
  }
}

export default new AdminService(config.API_ADMIN_SERVICE_ROOT);


import { TypedVue } from "@/store/types";
import { Component, Prop, Ref, Watch } from "vue-property-decorator";
import { Modal } from "bootstrap";
import { V2Project } from "@/store/admin/adminTypes";

@Component({})
export default class CreditManagementModal extends TypedVue {
  @Ref("manage-credits-modal")
  readonly modal!: Modal;

  @Prop()
  projects!: V2Project[];

  @Prop()
  show!: boolean;

  @Watch("show")
  onShowChange(show: boolean) {
    if (show) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  get sortedProjects() {
    return this.projects.sort((a, b) => {
      if (a.lastUpdated && b.lastUpdated) {
        return a.lastUpdated.localeCompare(b.lastUpdated);
      } else return a.identifier.localeCompare(b.identifier);
    });
  }

  public projectsFields = [
    { key: "name", label: "Project Space Name" },
    { key: "remaining", label: "Credits Remaining" },
    { key: "creditsUsed", label: "Credits Used" },
    { key: "credits", label: "Total Credits" },
  ];

  close(): void {
    this.$emit("close");
  }
}

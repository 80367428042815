import { render, staticRenderFns } from "./ProjectCreate.vue?vue&type=template&id=5d128a98&scoped=true"
import script from "./ProjectCreate.vue?vue&type=script&lang=ts"
export * from "./ProjectCreate.vue?vue&type=script&lang=ts"
import style0 from "./ProjectCreate.vue?vue&type=style&index=0&id=5d128a98&prod&lang=scss"
import style1 from "./ProjectCreate.vue?vue&type=style&index=1&id=5d128a98&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d128a98",
  null
  
)

export default component.exports

import { TypedVue } from "@/store/types";
import { Component } from "vue-property-decorator";
import { internalAPI } from "@/lib/s3PlatformApi";
import adminService from "@/services/adminService";
import { Project } from "@/store/internal/internalTypes";
import { Organization } from "@/store/admin/adminTypes";

interface GroupedOrganization {
  org_id: string;
  name: string;
  creditsUsed: number;
  creditsTotal: number;
  projects: Project[];
  _showDetails?: boolean; // custom property for row-details
}

@Component({
  name: "CreditManagement",
})
export default class CreditManagement extends TypedVue {
  public fields = [
    { key: "name", label: "Organization" },
    { key: "creditsUsed", label: "Credits Used" },
    { key: "creditsRemaining", label: "Credits Remaining" },
    { key: "creditsTotal", label: "Total Credits" },
  ];

  public projectsFields = [
    { key: "name", label: "Project" },
    { key: "creditsUsed", label: "Used" },
    { key: "remaining", label: "Remaining" },
    { key: "credits", label: "Total" },
    { key: "actions", label: "", class: "text-center" },
  ];

  public organizations: Organization[] = [];
  public projects: Project[] = [];
  public groupedOrgs: GroupedOrganization[] = [];

  // Tracks the collapse state per org ID
  public collapses: Record<string, boolean> = {};

  public selectedProject: Project | null = null;
  public credits = 0;
  public unlimitedCredits = false;
  public loading = false;

  async mounted(): Promise<void> {
    this.organizations = await adminService.getOrganizations();
    await this.getProjects();
  }

  async getProjects(): Promise<void> {
    await internalAPI.getManyProjects({ page: 1, page_size: 99999 }).then((response) => {
      this.projects = response.data.results;
    });

    const grouped: Record<string, GroupedOrganization> = {};
    for (const project of this.projects) {
      if (!grouped[project.orgId]) {
        grouped[project.orgId] = {
          org_id: project.orgId,
          name: this.organizations.find((org) => org.id === project.orgId)?.displayName || "N/A",
          creditsUsed: 0,
          creditsTotal: 0,
          projects: [],
          _showDetails: this.collapses[project.orgId] || false,
        };
      }

      grouped[project.orgId].projects.push(project);
      grouped[project.orgId].creditsUsed += project.creditsUsed || 0;
      if (project.credits && project.credits > 0) {
        grouped[project.orgId].creditsTotal += project.credits;
      }
    }

    // If all projects have -1 credits, set the total credits to -1
    for (const orgId in grouped) {
      const group = grouped[orgId];
      const allNegativeOne = group.projects.every((p) => p.credits === -1);

      if (allNegativeOne) {
        group.creditsTotal = -1;
      }
    }

    for (const group of Object.values(grouped)) {
      group.projects.sort((a, b) => a.name.localeCompare(b.name));
    }

    this.groupedOrgs = Object.values(grouped).sort((a, b) => a.name.localeCompare(b.name));
  }

  /**
   * Called when a row is clicked in b-table. Toggles the collapse state
   * for that organization and sets the _showDetails property
   */
  public toggleCollapse(item: GroupedOrganization): void {
    const orgId = item.org_id;
    if (this.collapses[orgId] === undefined) {
      // If no collapse state yet, initialize as true (expanded)
      this.$set(this.collapses, orgId, true);
      // Show the built-in b-table detail row
      this.$set(item, "_showDetails", true);
    } else {
      this.collapses[orgId] = !this.collapses[orgId];
      this.$set(item, "_showDetails", !item._showDetails);
    }
  }

  public async updateProject() {
    if (!this.selectedProject) {
      return;
    }
    this.loading = true;

    await internalAPI.updateProject(this.selectedProject.identifier, {
      name: this.selectedProject.name,
      description: this.selectedProject.description || "",
      logo: this.selectedProject.logo,
      assessmentIds: this.selectedProject.assessmentIds || [],
      visibleBenchmarks: this.selectedProject.visibleBenchmarks || [],
      credits: this.unlimitedCredits ? -1 : this.credits,
    });

    await this.getProjects();

    this.closeModal();
    this.loading = false;
  }

  public editProject(project: Project): void {
    this.selectedProject = project;
    this.credits = project.credits && project.credits > 0 ? project.credits : 0;
    this.unlimitedCredits = project.credits === -1;
    this.$bvModal.show("edit-modal");
  }

  closeModal(): void {
    this.selectedProject = null;
    this.credits = 0;
    this.unlimitedCredits = false;
    this.$bvModal.hide("edit-modal");
  }
}

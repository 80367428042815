import { render, staticRenderFns } from "./ManageCredits.vue?vue&type=template&id=aaf16068&scoped=true"
import script from "./ManageCredits.vue?vue&type=script&lang=ts"
export * from "./ManageCredits.vue?vue&type=script&lang=ts"
import style0 from "./ManageCredits.vue?vue&type=style&index=0&id=aaf16068&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf16068",
  null
  
)

export default component.exports
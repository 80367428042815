
import { TypedVue } from "@/store/types";
import { Component, Watch, Ref, Prop, Emit } from "vue-property-decorator";
import { Modal } from "bootstrap";
import { V2Project } from "@/store/admin/adminTypes";
import { Actions } from "@/store/admin/adminActions";
import { Action } from "vuex-class";
import { ProjectAssessment } from "@/lib/serviceTypes";
import { systemAPI } from "@/lib/s3PlatformApi";

const namespace = "admin";
@Component({})
export default class EditParticipantModal extends TypedVue {
  private emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  private candidateEmail = "";
  private candidateName = "";

  private isSaving = false;
  private isSendingEmail = false;
  private isGeneratingLink = false;

  private alertVisible = 0;
  private alertText = "";
  private alertType = "success";

  @Ref("add-participant-modal")
  readonly modal!: Modal;

  @Prop() show!: boolean;
  @Prop({ default: null }) assessment!: ProjectAssessment | null;
  @Emit("onParticipantUpdated")
  onParticipantUpdated(assessment: ProjectAssessment) {
    return assessment;
  }

  @Action(Actions.GET_ORG_PROJECTS, { namespace })
  getProjects!: () => Promise<V2Project[]>;

  @Watch("show")
  onShowChange(show: boolean) {
    if (show) {
      this.modal.show();
    } else {
      this.modal.hide();
    }
  }

  @Watch("assessment")
  onAssessmentChange() {
    if (this.assessment) {
      this.candidateName = this.assessment.candidate.name;
      this.candidateEmail = this.assessment.candidate.email;
    }
  }

  get isEmailValid(): boolean {
    return this.emailRegex.test(this.candidateEmail);
  }

  get isEdited(): boolean {
    return this.candidateName !== this.assessment?.candidate.name || this.candidateEmail !== this.assessment?.candidate.email;
  }

  get simulationIncomplete(): boolean {
    return this.assessment?.state === "incomplete" || this.assessment?.state === "in-progress";
  }

  mounted() {
    if (this.assessment) {
      this.candidateName = this.assessment.candidate.name;
      this.candidateEmail = this.assessment.candidate.email;
    }
  }

  async saveEdits() {
    if (this.assessment) {
      this.isSaving = true;
      await systemAPI
        .updateAssessment(this.assessment.identifier, { candidateName: this.candidateName, candidateEmail: this.candidateEmail })
        .then(async () => {
          await this.getProjects();
          this.alertType = "success";
          this.alertText = "Participant details updated successfully.";
          this.alertVisible = 5;
          // this.modal.hide();
        })
        .catch(() => {
          this.alertType = "danger";
          this.alertText = "Error updating participant details. Please try again later.";
          this.alertVisible = 5;
        })
        .finally(() => {
          this.isSaving = false;
        });
      this.onParticipantUpdated({ ...this.assessment, candidate: { name: this.candidateName, email: this.candidateEmail } });
    }
  }

  async sendEmail() {
    if (this.assessment) {
      this.isSendingEmail = true;
      await systemAPI
        .sendLaunchEmail(this.assessment.identifier)
        .then(async () => {
          this.alertType = "success";
          this.alertText = "Launch email sent successfully.";
          this.alertVisible = 5;
        })
        .catch(() => {
          this.alertType = "danger";
          this.alertText = "Error sending launch email. Please try again later.";
          this.alertVisible = 5;
        })
        .finally(() => {
          this.isSendingEmail = false;
        });
    }
  }

  async generateLink() {
    if (this.assessment && !this.isGeneratingLink) {
      this.isGeneratingLink = true;
      await systemAPI
        .getNewAssessmentToken(this.assessment.identifier)
        .then(async (res) => {
          const url = this.getFullTokenUrl(res.data.token, this.assessment?.language);
          navigator.clipboard.writeText(url).catch((err) => console.error(err));
          this.alertType = "success";
          this.alertText = "Link copied to clipboard";
          this.alertVisible = 5;
        })
        .catch(() => {
          this.alertType = "danger";
          this.alertText = "Error generating link. Please try again later.";
          this.alertVisible = 5;
        })
        .finally(() => {
          this.isGeneratingLink = false;
        });
    }
  }

  getFullTokenUrl(generatedToken: string, lang: string | undefined = undefined): string {
    let url = window.location.origin + "/?t=" + generatedToken;
    if (lang && lang !== "en") {
      url += "&lang=" + lang;
    }

    return url;
  }

  countDownChanged(dismissCountDown: number): void {
    this.alertVisible = dismissCountDown;
  }

  close(): void {
    this.$emit("close");
  }
}

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { authGuard } from "@/lib/auth/authGaurd";
import rg4js, { RaygunV2UserDetails } from "raygun4js";
import config from "@/lib/config";

import WelcomeSplash from "@/views/WelcomeSplash.vue";
import IdentityCheck from "@/views/IdentityCheck.vue";
import Introduction from "@/views/Introduction.vue";
import Agreements from "@/views/Agreements.vue";
import Instructions from "@/views/Instructions.vue";
import GenerateLink from "@/views/admin/GenerateLink.vue";
import Assessment from "@/views/Assessment.vue";
import AssessmentComplete from "@/views/AssessmentComplete.vue";
import SystemAdminLanding from "@/views/admin/SystemAdminLanding.vue";
import Summary from "@/views/admin/Summary.vue";
import AdminRedirect from "@/views/admin/AdminRedirect.vue";
import CandidateReport from "@/views/admin/CandidateReport.vue";
import GenerateELRAReport from "@/views/admin/GenerateELRAReport.vue";
import ManageProjectReports from "@/views/admin/ManageProjectReports.vue";
import ProjectList from "@/views/admin/ProjectList.vue";
import ProjectCreate from "@/views/admin/ProjectCreate.vue";
import AssessmentList from "@/views/admin/AssessmentList.vue";
import BenchmarkList from "@/views/admin/BenchmarkList.vue";
import TeamEffectivenessReport from "@/views/admin/TeamEffectivenessReport.vue";
import SearchReport from "@/views/admin/SearchReport.vue";
import Dashboard from "@/views/admin/DashboardView.vue";
import PageNotFound from "@/views/errors/404.vue";
import AccessDenied from "@/views/errors/401.vue";
import SelectOrganization from "@/views/admin/SelectOrganization.vue";
import ManageCredits from "@/views/admin/ManageCredits.vue";
import { TypedVue } from "@/store/types";
import { getInstance } from "@/lib/auth/VueAuth";

Vue.use(VueRouter);

const assessmentRoutes: Array<RouteConfig> = [
  {
    path: "/",
    name: "WelcomeSplash",
    component: WelcomeSplash,
    // component: () => import(/* webpackChunkName: "assessment-platform" */ "../views/WelcomeSplash.vue"),
    meta: {
      hideLogo: true,
      title: "System-3 - Welcome to the Assessment Platform",
    },
  },
  {
    path: "/introduction",
    name: "Introduction",
    component: Introduction,
    meta: {
      title: "System-3 - Introduction",
    },
    // component: () => import(/* webpackChunkName: "assessment-platform" */ "../views/Introduction.vue"),
  },
  {
    path: "/identification",
    name: "Identification",
    component: IdentityCheck,
    meta: {
      title: "System-3 - Identification",
    },
  },
  {
    path: "/agreements",
    name: "Agreements",
    component: Agreements,
    meta: {
      title: "System-3 - Agreements",
    },
    // component: () => import(/* webpackChunkName: "assessment-platform" */ "../views/Agreements.vue"),
  },
  {
    path: "/instructions",
    name: "Instructions",
    component: Instructions,
    meta: {
      title: "System-3 - Instructions",
    },
    // component: () => import(/* webpackChunkName: "assessment-platform" */ "../views/Instructions.vue"),
  },
  {
    path: "/assessment",
    name: "Assessment",
    component: Assessment,
    meta: {
      title: "System-3 - Assessment",
    },
    // component: () => import(/* webpackChunkName: "assessment-platform" */ "../views/Assessment.vue"),
  },
  {
    path: "/complete",
    name: "Complete",
    meta: {
      title: "System-3 - Assessment Complete",
    },
    component: AssessmentComplete,
    // component: () => import(/* webpackChunkName: "assessment-platform" */ "../views/AssessmentComplete.vue"),
  },
];

const dashboardRoutes: Array<RouteConfig> = [
  {
    path: "/report/:id",
    name: "Report",
    component: CandidateReport,
    // component: () => import(/* webpackChunkName: "assessment-dashboard" */ "../views/admin/Report.vue"),
    meta: {
      requireAuth: true,
      hideFooter: true,
      allowNoOrg: true,
      backgroundColour: "#FFFFFF",
      title: "System-3 - Your Report",
      header: {
        fetchDataOnMount: false,
      },
    },
  },
  {
    path: "/accessdenied",
    name: "AccessDenied",
    component: AccessDenied,
    // component: () => import(/* webpackChunkName: "assessment-dashboard" */ "../views/errors/401.vue"),
    meta: {
      hideFooter: true,
      title: "System-3 - Access Denied",
    },
  },
  {
    path: "/404",
    name: "404",
    component: PageNotFound,
    // component: () => import(/* webpackChunkName: "assessment-dashboard" */ "../views/errors/404.vue"),
    meta: {
      hideFooter: true,
      title: "System-3 - Page Not Found",
    },
  },
  {
    path: "/admin/",
    name: "AdminLanding",
    redirect: "/admin/selectOrganization",
  },
  {
    path: "/admin",
    name: "Dashboard",
    component: Dashboard,
    // component: () => import(/* webpackChunkName: "assessment-dashboard" */ "../views/admin/DashboardView.vue"),
    meta: {
      requireAuth: true,
      hideFooter: true,
      backgroundColour: "#F8F9FC",
      title: "System-3 - Your Dashboard",
    },
    children: [
      // {
      //   path: "tmp",
      //   component: SearchReport,
      //   meta: {
      //     requireAuth: true,
      //     hideFooter: true,
      //   },
      // },
      {
        path: "summary",
        component: Summary,
        // component: () => import(/* webpackChunkName: "assessment-dashboard" */ "../views/admin/Summary.vue"),
        meta: {
          requireAuth: true,
          hideFooter: true,
          backgroundColour: "white",
          title: "System-3 - Your Dashboard",
          header: {
            showProjectSelector: true,
            showProjectSettings: true,
          },
        },
      },
      {
        path: "selectOrganization",
        name: "SelectOrganization",
        component: SelectOrganization,
        meta: {
          requireAuth: true,
          hideFooter: true,
          title: "System-3 - Select Your Organisation",
          allowNoOrg: true,
          ignoreTOS: true,
          header: {
            enabled: false,
          },
        },
      },
      {
        path: "candidate/:id",
        component: CandidateReport,
        // component: () => import(/* webpackChunkName: "assessment-dashboard" */ "../views/admin/CandidateReport.vue"),
        meta: {
          requireAuth: true,
          hideFooter: true,
          backgroundColour: "#FFFFFF",
          title: "System-3 - Leaders Report",
          header: {
            showProjectSettings: true,
            showProjectSelector: true,
          },
        },
      },
      {
        path: "team-effectiveness-report/:id",
        name: "TeamEffectivenessReport",
        component: TeamEffectivenessReport,
        meta: {
          requireAuth: true,
          hideFooter: true,
          title: "System-3 - Team Effectiveness Report",

          header: {
            showProjectSettings: true,
            showProjectSelector: true,
            links: [
              { text: "Report Overview", icon: "chat-square-text", to: "#report-overview" },
              { text: "Leadership Signatures", icon: "pie-chart", to: "#leadership-signatures" },
              { text: "Group Alignment", icon: "person", to: "#team-alignment" },
              { text: "Summary Matrix", icon: "bar-chart-fill", to: "#summary-matrix" },
            ],
          },
        },
      },
      {
        path: "search-report/:id",
        name: "SearchReport",
        component: SearchReport,
        meta: {
          requireAuth: true,
          hideFooter: true,
          title: "System-3 - Search Report",

          header: {
            showProjectSettings: true,
            showProjectSelector: true,
            links: [
              { text: "Report Overview", icon: "chat-square-text", to: "#report-overview" },
              { text: "Competencies Overview", icon: "inboxes-fill", to: "#competencies-overview" },
              { text: "Candidates at a Glance", icon: "card-checklist", to: "#candidates-at-a-glance" },
              { text: "Summary Matrix", icon: "bar-chart-fill", to: "#summary-matrix" },
              { text: "Leadership Signatures", icon: "pie-chart", to: "#leadership-signatures" },
            ],
          },
        },
      },
    ],
  },
];

const adminRoutes: Array<RouteConfig> = [
  {
    path: "/admin/system",
    name: "SystemAdmin",
    component: SystemAdminLanding,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/SystemAdminLanding.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/generate-link",
    name: "GenerateLink",
    component: GenerateLink,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/GenerateLink.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/generate-report",
    name: "GenerateELRAReport",
    component: GenerateELRAReport,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/GenerateReport.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/project-reports",
    name: "ManageProjectReports",
    component: ManageProjectReports,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/GenerateReport.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/assessment-list",
    name: "Assessments",
    component: AssessmentList,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/AssessmentList.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/project-list",
    name: "Projects",
    component: ProjectList,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/ProjectList.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/benchmark-list",
    name: "Benchmarks",
    component: BenchmarkList,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/BenchmarkList.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/project-create",
    name: "ProjectCreate",
    component: ProjectCreate,
    // component: () => import(/* webpackChunkName: "assessment-admin" */ "../views/admin/ProjectCreate.vue"),
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
  {
    path: "/admin/manage-credits",
    name: "ManageCredits",
    component: ManageCredits,
    meta: {
      requireAuth: true,
      requiredRole: "GlobalAdmin",
      hideFooter: true,
      backgroundColour: "#F8F9FC",
    },
  },
];

const routes = [...assessmentRoutes, ...dashboardRoutes, ...adminRoutes, ...[{ path: "*", component: PageNotFound, meta: { hideFooter: true } }]];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(authGuard);
router.beforeEach((to, from, next) => {
  if (router.app) {
    if (router.app.$store?.state?.assessmentStatusSummary?.assessmentId) {
      if (config.RAYGUN_KEY) {
        rg4js("setUser", {
          identifier: router.app.$store.state.assessmentStatusSummary.assessmentId,
          isAnonymous: false,
        } as RaygunV2UserDetails);
      }
    }

    const typedApp = router.app as TypedVue;

    if (typedApp.$matomo) {
      if (typedApp.$matomo && router.app.$store?.state?.assessmentStatusSummary?.assessmentId) {
        typedApp.$matomo.setUserId(router.app.$store.state.assessmentStatusSummary.assessmentId);
      } else {
        const authService = getInstance();

        if (authService.auth0Client) {
          authService.getUser().then((user) => {
            if (user?.sub) {
              typedApp.$matomo.setUserId(user.sub);
            }
          });
        }
      }
    }
  }

  next();
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title || "Assessment Platform";
  next();
});

router.afterEach((to, from) => {
  if (config.RAYGUN_KEY) {
    rg4js("trackEvent", {
      type: "pageView",
      path: to.path,
    });
  }
});

export default router;


import { TypedVue } from "@/store/types";
import { Component, Watch } from "vue-property-decorator";
import adminService from "@/services/adminService";
import { Project } from "@/store/admin/adminTypes";
import { internalAPI } from "@/lib/s3PlatformApi";

interface SelectItem {
  text: string;
  value: string | null;
}

@Component({})
export default class ProjectCreate extends TypedVue {
  projectName = "";
  projectType = "";
  selectedOrganization: string | null = null;
  newOrganization = "";
  errorMessage = "";
  logo: File | null = null;
  logoUrl = "";
  logoUploading = false;
  orgs: SelectItem[] = [];
  diversity = false;
  rootBenchmark: string | null = null;
  selectedRootBenchmark: string | null = null;
  credits = 0;
  unlimitedCredits = false;
  createdProject: Project | null = null;
  alertVisible = 0;

  rootBenchmarks: SelectItem[] = [];

  @Watch("logo")
  onLogoChanged(newLogo: File): void {
    this.logoUrl = "";
    this.errorMessage = "";
    this.logoUploading = true;
    adminService
      .uploadLogo(newLogo)
      .then((url) => {
        this.logoUrl = url;
      })
      .finally(() => {
        this.logoUploading = false;
      })
      .catch((err) => {
        console.error(err);
        this.logo = null;
        this.errorMessage = "Failed to upload logo";
      });
  }

  @Watch("selectedOrganization")
  async onSelectedOrganizationChanged(newOrg: string): Promise<void> {
    if (newOrg === "" || newOrg === null) {
      this.rootBenchmark = null;
    }
    this.rootBenchmark = await this.getOrgRootBenchmark(newOrg);
    if (this.rootBenchmark) {
      this.selectedRootBenchmark = this.rootBenchmark;
    }
  }

  @Watch("newOrganization")
  async onNewOrganizationChanged(newOrg: string): Promise<void> {
    if (newOrg !== "") {
      this.rootBenchmark = null;
      this.selectedOrganization = null;
    }
  }

  async mounted(): Promise<void> {
    await this.getOrgs().catch((err) => console.error(err));
    await this.getRootBenchmarks().catch((err) => console.error(err));
  }

  async getOrgs(): Promise<void> {
    const organizations = (await internalAPI.getOrganizations()).data.map((org) => {
      return { text: org.displayName, value: org.id };
    });
    this.orgs = organizations;
  }

  async getRootBenchmarks(): Promise<void> {
    const benchmarks = (await internalAPI.getManyBenchmarks({ is_root: true, page_size: 9999 })).data.results.map((bm) => {
      return { text: bm.name, value: bm.identifier };
    });
    this.rootBenchmarks = benchmarks;
  }

  async getOrgRootBenchmark(orgId: string): Promise<string | null> {
    const resp = await internalAPI.getManyProjects({ org_id: orgId, page_size: 1 });
    if (resp.data.results.length > 0) {
      return resp.data.results[0].rootBenchmarkId;
    }

    return null;
  }

  validateOrganization(): boolean {
    // eslint-disable-next-line
    const regex = new RegExp("^[a-z0-9\-_]+$");
    if (this.newOrganization.length < 3 || this.newOrganization.length > 50) {
      this.errorMessage = "Organization name must be between 3 and 50 characters.";
      return false;
    }
    if (!regex.test(this.newOrganization)) {
      this.errorMessage = "Organization name may only container the characters a-z, 0-9, -, _";
      return false;
    }
    return true;
  }

  async submitProject(): Promise<any> {
    if (this.projectName.length === 0) {
      this.errorMessage = "Please enter a project name.";
      return;
    }

    if (this.selectedOrganization === "" && this.newOrganization === "") {
      this.errorMessage = "Please provide an organization.";
      return;
    }

    if (this.selectedRootBenchmark === null) {
      this.errorMessage = "Please select a root benchmark.";
      return;
    }

    if (this.newOrganization.length > 0) {
      if (!this.validateOrganization()) {
        return;
      }
    }

    const resp = await internalAPI.createProject({
      name: this.projectName,
      projectType: this.projectType,
      selectedOrganization: this.selectedOrganization || undefined,
      newOrganization: this.newOrganization,
      logo: this.logoUrl,
      features: this.diversity ? ["diversity"] : [],
      rootBenchmarkId: this.selectedRootBenchmark || "",
      credits: this.unlimitedCredits ? -1 : this.credits,
    });

    if (!resp) {
      this.errorMessage = "There was an error creating the project or organization. View the console output for additional information.";
      this.createdProject = null;
    }
    if (resp.status === 201) {
      this.projectName = "";
      this.projectType = "";
      this.selectedOrganization = null;
      this.selectedRootBenchmark = null;
      this.rootBenchmark = null;
      this.credits = 0;
      this.unlimitedCredits = false;
      this.newOrganization = "";
      this.errorMessage = "";
      this.createdProject = resp.data;
    }
    return resp;
  }

  copyProjectId(): void {
    navigator.clipboard
      .writeText(this.createdProject?.projectId ?? "")
      .then(() => (this.alertVisible = 4))
      .catch((err) => console.error(err));
  }

  countDownChanged(dismissCountDown: number): void {
    this.alertVisible = dismissCountDown;
  }
}

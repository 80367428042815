
import { TypedVue } from "@/store/types";
import { Component, Prop } from "vue-property-decorator";
import { systemAPI } from "@/lib/s3PlatformApi";
import ProjectDropdown from "@/components/input/ProjectDropdown.vue";
import { Organization, Project, V2Project } from "@/store/admin/adminTypes";
import { Actions } from "@/store/admin/adminActions";
import { Action } from "vuex-class";
import { AxiosError } from "axios";

const namespace = "admin";
@Component({ components: { ProjectDropdown } })
export default class GenerateLink extends TypedVue {
  private generatedToken = "";
  private candidateEmail = "";
  private projectType = "";
  private selectedProject: Project | null = null;
  private selectedLang = "en";
  private organizations: Organization[] = [];
  private projects: Project[] = [];
  private orgsLoading = false;
  private projectsLoading = false;
  private alertVisible = 0;
  private alertText = "";
  private sendEmail = false;
  private tokenLoading = false;
  private error: string | null = null;

  private emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  private langOptions = [
    { text: "English", value: "en" },
    { text: "French", value: "fr" },
  ];

  public candidateName = "";

  @Prop({ default: null }) project!: V2Project | null;
  @Prop({ default: false }) refreshDataAfterGenerate!: boolean;

  @Action(Actions.GET_ORG_PROJECTS, { namespace })
  refreshProjects!: () => Promise<V2Project[]>;

  get fullTokenUrl(): string {
    let url = window.location.origin + "/?t=" + this.generatedToken;
    if (this.selectedLang !== "en") {
      url += "&lang=" + this.selectedLang;
    }

    return url;
  }

  get isLoading(): boolean {
    return this.orgsLoading || this.projectsLoading;
  }

  get isEmailValid(): boolean {
    return this.emailRegex.test(this.candidateEmail);
  }

  get projectId(): string | null {
    if (this.project) {
      return this.project.identifier;
    }

    if (this.selectedProject) {
      return this.selectedProject.projectId;
    }

    return null;
  }

  get orgId(): string | null {
    if (this.project) {
      return this.project.orgId;
    }

    if (this.selectedProject) {
      return this.selectedProject.organization;
    }

    return null;
  }

  // async mounted(): Promise<void> {
  //   this.orgsLoading = this.projectsLoading = true;
  //   await this.getOrganizations().finally(() => (this.orgsLoading = false));
  //   await this.getProjects().finally(() => (this.projectsLoading = false));
  // }

  // async getOrganizations(): Promise<void> {
  //   this.organizations = await adminService.getOrganizations();
  // }

  // async getProjects(): Promise<void> {
  //   await adminService.getProjects(null, null, false).then((res) => {
  //     this.projects = res;
  //   });
  // }

  resetForm(): void {
    this.candidateName = "";
    this.candidateEmail = "";
    this.selectedProject = null;
    this.sendEmail = false;
  }

  generateLink(): void {
    if (this.projectId && this.orgId) {
      this.tokenLoading = true;
      this.generatedToken = "";
      this.error = null;

      systemAPI
        .createAssessment({
          candidateName: this.candidateName.trim(),
          candidateEmail: this.candidateEmail.trim(),
          organization: this.orgId,
          project: this.projectId,
          projectType: this.projectType.trim(),
          language: this.selectedLang,
          sendEmail: this.sendEmail,
          autoGenerateReport: true,
        })
        .then(async (res) => {
          this.generatedToken = res.data.token;
          await this.refreshProjects();
          this.alertText = this.sendEmail ? "Link successfully created and email sent." : "Link successfully created.";
          this.alertVisible = 5;
          this.resetForm();
        })
        .catch((err: AxiosError) => {
          console.log(err);
          this.error = (err.response?.data as { detail: string }).detail || "An error occurred while generating the token. Please try again later.";
        })
        .finally(() => {
          this.tokenLoading = false;
        });
    }
  }

  countDownChanged(dismissCountDown: number): void {
    this.alertVisible = dismissCountDown;
  }

  copyAssessmentUrl(): void {
    navigator.clipboard.writeText(this.fullTokenUrl).catch((err) => console.error(err));
  }
}

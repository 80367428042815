
import ProjectVisualSummary from "@/components/admin/ProjectVisualSummary.vue";
import StateBadge from "@/components/platform/StateBadgeComponent.vue";
import AddParticipantModal from "@/components/platform/AddParticipantModal.vue";
import EditParticipantModal from "../platform/EditParticipantModal.vue";

import { Getter } from "vuex-class";
import { TypedVue } from "@/store/types";
import { ProjectAggregates, ProjectAssessment } from "@/lib/serviceTypes";
import { ProjectReportShort } from "@/store/admin/adminTypes";
import { V2Project, Assessment, ProjectTheme } from "@/store/admin/adminTypes";
import { Component } from "vue-property-decorator";
import { Getters } from "@/store/admin/adminGetters";
import moment from "moment-mini";

const namespace = "admin";
export interface SummaryAssessment extends Assessment {
  shortId: string;
  pinned: boolean;
}

@Component({
  components: {
    ProjectVisualSummary,
    StateBadge,
    AddParticipantModal,
    EditParticipantModal,
  },
})
export default class SummaryProject extends TypedVue {
  summaryData: any = {};

  // assessments: any[] = assessmentData;
  showNames = false;
  assessmentToEdit: ProjectAssessment | null = null;
  addParticipantModalVisible = false;
  editParticipantModalVisible = false;

  searchText = "";
  filter: string | null = null;
  filterOptions = [
    { value: null, text: "All" },
    { value: "incomplete", text: "Incomplete" },
    { value: "in-progress", text: "In Progress" },
    { value: "scoring_complete", text: "Pending" },
    { value: "report-generated", text: "Complete" },
  ];

  @Getter(Getters.GET_CURRENT_PROJECT, { namespace })
  public currentProject!: V2Project | null;

  @Getter(Getters.GET_CURRENT_PROJECT_SHORT_ASSESSMENTS, { namespace })
  public assessments!: ProjectAssessment[];

  @Getter(Getters.GET_CURRENT_PROJECT_SHORT_REPORTS, { namespace })
  public projectReports!: ProjectReportShort[];

  @Getter(Getters.GET_CURRENT_PROJECT_THEME, { namespace })
  public projectTheme!: ProjectTheme;

  @Getter(Getters.GET_CURRENT_PROJECT_AGGREGATES, { namespace })
  public projectAggregates!: ProjectAggregates;

  @Getter(Getters.CURRENT_PROJECT_DIVERSITY_ENABLED, { namespace })
  public projectHasDiversity!: boolean;

  get filteredAssessments(): any[] {
    return this.assessments
      .filter((assessment) => {
        if (this.searchText) {
          return assessment.candidate.name.toLowerCase().includes(this.searchText.toLowerCase());
        }
        if (this.filter) {
          if (this.filter === "scoring_complete") {
            return assessment.state === this.filter || assessment.state === "complete";
          }
          return assessment.state === this.filter;
        }
        return true;
      })
      .sort((a, b) => {
        return a.candidate.name < b.candidate.name ? -1 : 1;
        // if (this.filterOptions.findIndex((o) => o.value === a.state) > this.filterOptions.findIndex((o) => o.value === b.state)) {
        //   return -1;
        // }
        // if (this.filterOptions.findIndex((o) => o.value === a.state) < this.filterOptions.findIndex((o) => o.value === b.state)) {
        //   return 1;
        // }
        // return 0;
      });
  }

  get currentProjectId(): string {
    if (this.currentProject) {
      return this.currentProject.identifier;
    }
    return "";
  }

  get creditsRemaining(): number {
    if (this.currentProject) {
      if (this.currentProject.credits && this.currentProject.credits - this.currentProject.creditsUsed >= 0) {
        return this.currentProject.credits - this.currentProject.creditsUsed;
      }
    }
    return 0;
  }

  get userIsAdmin(): boolean {
    const user = this.$auth.user;
    if (user) {
      return user.isOrgAdmin;
    }
    return false;
  }

  editParticipant(assessment: ProjectAssessment) {
    this.assessmentToEdit = assessment;
    this.editParticipantModalVisible = true;
  }

  onParticipantUpdated(assessment: ProjectAssessment) {
    this.assessmentToEdit = assessment;
  }

  toFriendlyDate(date: string): string {
    const mDate = moment.utc(date).local();
    return mDate.format("MMMM Do, YYYY");
  }
}
